import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CoreModule } from '../core/core.module';
import { TravelComponent } from './travel/travel.component';
import { ShhhComponent } from './shhh/shhh.component';


@NgModule({
  declarations: [IndexComponent, TravelComponent, ShhhComponent],
  imports: [
    CommonModule,
    CoreModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    LeafletModule,
  ],
  exports: [
    IndexComponent,
    TravelComponent,
  ]
})
export class HomeModule { }
