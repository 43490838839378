<section class="hero is-info is-large">
    <div class="hero-body">
        <div class="container">
            <h1 class="title">Karen Toro Birthday Month</h1>
            <h2 class="subtitle">Let's help her celebrate all month long!</h2>
        </div>
    </div>

</section>

<section class="section" id="about">
    <!-- Title -->
    <div class="section-heading">
        <h3 class="title is-2">What's this all about?</h3>
    </div>
    <div class="container">
        <div class="columns is-mobile is-centered">
            <div class="column">
                <p>I (Sam) have noticed, under a normal year's circumstances, Karen spends the entire month of
                    September celebrating her birthday.
                    In addition to a grand party, she enjoys the intimate settings of getting dinner with 1's or 2's
                    of her
                    friends.
                    These occasions stretch out naturally with scheduling conflicts and the like.
                    I've even seen it wrap into October, and naturally, I've enjoyed some good old-fashioned teasing
                    about
                    this routine.
                </p>
                <br />
                <p>
                    This year is unlike any other, and hopefully it stays that way for the remaining years of our
                    lives.
                    She won't be able to celebrate on the same scale as years previous, and this birthday is a
                    milestone
                    year.
                </p>
                <br />
                <p>
                    To celebrate and pay homage to her normal birthday traditions,
                    I'm inviting her friends, from all corners of her life (past and present) to "help me help her"
                    celebrate her birthday for a whole month digitally. Ideally, <strong>WE</strong> get 30+ people
                    for each
                    day in September. Once you sign-up, you will get a text to opt-in and be assigned a Birthday Month
                    Day. I
                    don't have many of her friend's numbers, so please share this link with others so we get the whole
                    month covered.
                </p>
                <br />
                <p>
                    Participation is easy, won't take much effort, and will fill her heart continually for the
                    month. Also,
                    please don't think that this precludes you from wishing her well on her actual birthday. Nothing
                    should
                    stand in the way of your premade celebrations or plans, this is more of a feel-good month long
                    celebration extension.
                </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title">The Process is Easy</p>
            </header>
            <div class="card-content">
                <ol>
                    <li>
                        Sign-up.
                    </li>
                    <li>
                        Get a Text Message. Confirm to opt-in.
                    </li>
                    <li>
                        Get your assigned day.
                    </li>
                    <li>
                        Get reminded day of, send a text saying Happy Birthday Month!
                    </li>
                </ol>
            </div>
        </div>
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfjZy4v0sI4dh4n5nuICXpeg7myVqbqY2s7GvK9MX41_I9ayQ/viewform?embedded=true"
            width="640" height="730" frameborder="0" marginheight="0" marginwidth="0">Loading…
        </iframe>
    </div>
</section>