import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './home/index/index.component';
import { TravelComponent } from './home/travel/travel.component';
import { ShhhComponent } from './home/shhh/shhh.component';


const routes: Routes = [

  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'travel',
    component: TravelComponent
  },
  {
    path: 'shhh',
    component: ShhhComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
