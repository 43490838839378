<section id="map">
    <div style="height: 66vh;" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
    </div>
</section>
<div class="is-divider"></div>
<section id="description" class="section">
    <div class="container">
        <div class="columns">
            <div class="column is-9 is-offset1">
                <div class="content is-medium">
                    <h1 class="title">The World is Getting Smaller, but it's still Pretty Big.</h1>
                    <h2 class="subtitle is-6">
                        While not an exhaustive map, this is a rough estimate of places I've visited since turning on
                        Google's
                        location history tracking.
                    </h2>
                    <p>
                        For me, travelling has been an important exercise in sanity, discovery, and perspective.
                        If anywhere on the map looks interesting to you, feel free to reach out.
                        I am happy to discuss my experience.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>