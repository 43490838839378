<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="['/']">
            <span><strong>SV</strong></span>
        </a>

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
            data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
            <a class="navbar-item" href="#about">
                <span class="icon">
                    <i class="fas fa-user-tie"></i>
                </span>
                &nbsp; About
            </a>
            <a class="navbar-item" [routerLink]="['/travel']">
                <span class="icon">
                    <i class="fas fa-globe"></i>
                </span>
                &nbsp; Travel
            </a>
        </div>

        <div class="navbar-end">
            <a class="navbar-item" target="_blank" href="https://samverhasselt.substack.com">
                <span class="icon">
                    <i class="fas fa-pencil-alt"></i>
                </span>
                &nbsp; Blog
            </a>
            <a class="navbar-item" target="_blank" href="https://github.com/SaymV">
                <span class="icon">
                    <i class="fab fa-github"></i>
                </span>
                &nbsp; Github
            </a>
            <a class="navbar-item" target="_blank" href="https://linkedin.com/in/samverhasselt">
                <span class="icon">
                    <i class="fab fa-linkedin"></i>
                </span>
                &nbsp; LinkedIn
            </a>
            <a class="navbar-item" target="_blank" href="https://twitter.com/samverhasselt">
                <span class="icon">
                    <i class="fab fa-twitter"></i>
                </span>
                &nbsp; Twitter
            </a>
            <a class="navbar-item" target="_blank" href="https://www.instagram.com/saymv/">
                <span class="icon">
                    <i class="fab fa-instagram"></i>
                </span>
                &nbsp; Instagram
            </a>
            <div class="navbar-item">
                <div class="buttons">
                    <a class="button is-primary is-link" target="_blank"
                        href="mailto:sam@samverhasselt.com?subject=Let's%20Connect!&body=Hi%20Sam,%20\n">
                        <span class="icon">
                            <i class="far fa-envelope"></i>
                        </span>
                        <strong>&nbsp;Get In Touch</strong>
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>