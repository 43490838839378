<section class="hero is-info is-fullheight is-fullheight-with-navbar">
    <div class="hero-head">
        <br />
        <div class="container">
            <span>Hi! I' m</span>
            <h1 class="title is-1">
                Sam Verhasselt
            </h1>
            <h2 class="subtitle is-3">
                Software Engineer
            </h2>
        </div>

    </div>
</section>

<section class="section" id="about">
    <!-- Title -->
    <div class="section-heading">
        <h3 class="title is-2">About Me</h3>
        <h4 class="subtitle is-5">Jack of all trades, master of a few.</h4>
        <div class="container">
            <p>Software Engineer with more than <strong>6 years</strong> of well-rounded experience with a degree in the
                field of <strong>Computer Science</strong>, extensive knowledge of the full stack and experience with
                Big Data.
            </p>
        </div>
    </div>

    <div class="columns has-same-height">
        <div class="column">
            <!-- Profile -->
            <div class="card">
                <div class="card-content">
                    <h3 class="title is-4">Profile</h3>

                    <div class="content">
                        <table class="table is-hoverable">
                            <tbody>

                                <tr>
                                    <th colspan="1"></th>
                                    <th colspan="2"></th>
                                </tr>
                                <tr>
                                    <td>Full Name:</td>
                                    <td>Samuel James Verhasselt</td>
                                </tr>
                                <tr>
                                    <td>Home town:</td>
                                    <td>Santa Barbara, Ca</td>
                                </tr>
                                <tr>
                                    <td>University:</td>
                                    <td>Loyola Marymount</td>
                                </tr>
                                <tr>
                                    <td>Handedness:</td>
                                    <td>Left</td>
                                </tr>
                                <tr>
                                    <td>Preferred # of wheels:</td>
                                    <td>2 (Specialized Diverge, Yamaha FZ-07)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div class="column is-one-quarter">
            <!-- Profile picture -->
            <div class="card">
                <div class="card-image">
                    <figure class="image is-2by3">
                        <img src="assets/img/sam_with_sam.jpg" alt="Sam under Sam Sign">
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content">
                        Secret skill: Standing with sunglasses on.
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <!-- Skills -->
            <div class="card">
                <div class="card-content skills-content">
                    <h3 class="title is-4">Skills</h3>
                    <div class="content">

                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p>
                                        <strong>Javascript & Typescript</strong>
                                        <br>
                                        <progress class="progress is-primary" value="90" max="100"></progress>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p>
                                        <strong>Angular</strong>
                                        <br>
                                        <progress class="progress is-primary" value="50" max="100"></progress>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p>
                                        <strong>Java & Scala</strong>
                                        <br>
                                        <progress class="progress is-primary" value="75" max="100"></progress>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p>
                                        <strong>Python</strong>
                                        <br>
                                        <progress class="progress is-primary" value="85" max="100"></progress>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p>
                                        <strong>Hadoop & Spark</strong>
                                        <br>
                                        <progress class="progress is-primary" value="66" max="100"></progress>
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>