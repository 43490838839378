import { Component, OnInit, Input } from '@angular/core';
import { OktaAuthService, UserClaims } from '@okta/okta-angular';
import Navbar from '@vizuaalog/bulmajs/src/plugins/navbar';
import { IDToken } from '@okta/okta-angular/src/okta/models/token-manager';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input()
  isAuthenticated: boolean;

  userClaims: Promise<UserClaims>;
  userName = '';

  constructor(public oktaAuth: OktaAuthService) { }

  ngOnInit() {
    this.userClaims = this.oktaAuth.getUser();
    Navbar.create();
  }

  signUp() {
    this.oktaAuth.loginRedirect('/settings');
  }

  login() {
    this.oktaAuth.loginRedirect('/dashboard');
  }

  logout() {
    this.oktaAuth.logout('/');
  }

}
