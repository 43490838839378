import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat.js';
import { points } from '../../../assets/smallest';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {

  esriWorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping,' +
      'Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  });

  options = {
    layers: [
      this.esriWorldImagery
      // L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 3,
    center: L.latLng(10.215419, -52.021267)
  };




  constructor() { }

  ngOnInit(): void {
  }

  onMapReady(map) {
    const newAddressPoints = points.map((p) => [p.lat, p.lon]);
    const gradient = { 0.4: 'blue', 0.65: 'lime', 1: 'red' };
    const heat = L.heatLayer(newAddressPoints, { radius: 10, blur: 5, minOpacity: .5, }).addTo(map);
  }

}
