// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  oktaConfig: {
    issuer: 'https://login.letsreroute.com/oauth2/aus3eytxa9RzJxtJY357',
    redirectUri: 'http://localhost:4200/implicit/callback',
    clientId: '0oa10vi2q6nzl6O0x357',
    scope: 'email profile'
  },

  googleConfig: {
    client_id: '360834596987-aajmjinvccjo36odl54jnqvsqrftb6dm.apps.googleusercontent.com',
    redirect_uri: 'http://localhost:4200/settings',
    scopes: [
      'https://www.googleapis.com/auth/content',
    ].join(' ') // It's a space separated string
  },

  stripeConfig: {
    publicKey: 'pk_test_do92djT3gibg6EMhVrEFONhf00YY9WwnN0'
  },

  hasuraUrl: 'http://localhost:8081/v1/graphql'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
