import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from './../environments/environment';
import { OktaAuthModule } from '@okta/okta-angular';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { CookieService } from 'ngx-cookie-service';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpClientModule } from '@angular/common/http';
import { ApolloClient } from 'apollo-client';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    HomeModule,
    CoreModule,
    OktaAuthModule.initAuth(environment.oktaConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [CookieService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink) => {
        const auth = JSON.parse(localStorage.getItem('okta-token-storage'));
        const tokenKey = 'accessToken';
        return new ApolloClient({
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.hasuraUrl,
            headers: {
              Authorization: `Bearer ${auth[tokenKey][tokenKey]}`
            }
          })
        });
      },
      deps: [HttpLink]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
