<footer class="footer">
    <div class="container">
        <nav class="level">
            <!-- Left side -->
            <div class="level-item has-text-centered">
                <p class="level-item"><a [routerLink]="['/']">Home</a></p>
            </div>
            <!-- <div class="level-item has-text-centered">
                <p class="level-item"><a [routerLink]="['/#about']">About Me</a></p>
            </div> -->
            <div class="level-item has-text-centered">
                <p class="level-item"><a [routerLink]="['/travel']">Travel</a></p>
            </div>
        </nav>
        <p class="has-text-centered">Copyright Sam Verhasselt {{date}} &copy;</p>
    </div>
</footer>